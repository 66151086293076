<template>
	<div>
		<br />
		<div class="col-sm-12 mb-4 text-right">
			<h1>Facturas</h1>
		</div>

		<DataGrid ref="dataGrid" :data="facturas" :select="seleccionar" :config="dataGridConfig" @actions="dataGridActions" @ready="buscar_botones">
			<div class="col-md-2 v-step-bt-1">
				<button class="btn warning-btn" @click="cargar_facturas">Recargar</button>
			</div>
			<div v-if="$auth.can('proveedores','cargar_facturas') && $auth.getUser()" class="col-md-2 v-step-bt-2 v-step-factura-1">
				<button class="btn complementary-btn" @click="ver_cargar_factura">Cargar Factura/Complemento</button>
			</div>
			<div v-if="$auth.can('proveedores','ver_comentarios')" class="col-md-2 v-step-bt-3">
				<button class="btn secondary-btn" @click="mostrar_detalles">Detalles</button>
			</div>
			<div class="col-md-2">
				<div class="dropdown v-step-bt-4">
				  <button class="btn complementary-principal-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				    Ayuda
				  </button>
				  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'botones')">Interface</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'facturas')">Cargar factura/complemento de pago</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'detalles')">Ver detalles de facturas</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'filtros')">Filtros</a>
				  </div>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="subirFactura && $auth.can('proveedores','cargar_facturas')" class="bpb-modal" @close="cancelar_cargar_factura">
			<h2 class="title">Cargar factura</h2>
			<div class="body">
				<div class="row form-group">
					<label for="pdf" class="col-form-label col-sm-2">Documento PDF</label>
					<div class="col-sm-10 v-step-factura-2">
						<input type="file" name="pdf" id="pdf" accept=".pdf">
					</div>
				</div>
				<div class="row form-group">
					<label for="xml" class="col-form-label col-sm-2">Documento XML</label>
					<div class="col-sm-10 v-step-factura-3">
						<input type="file" name="xml" id="xml" accept=".xml">
					</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-2">Otros documentos (opcional)</label>
					<div class="col-sm-10">
						<div class="row mb-2">
							<div class="col-sm-12 text-right">
								<button class="btn btn-info v-step-factura-4" @click="seleccionar_documentos_extras">Cargar documento</button>
								<input type="file" name="doc_extra" id="doc_extra" style="display: none" @change="agregar_documentos_extras">
							</div>
						</div>
						<div class="content-table">
							<table class="other_files">
								<thead>
									<th>#</th>
									<th>Documento</th>
									<th>&nbsp;</th>
								</thead>
								<tbody v-if="documentos_extras.length > 0">
									<tr v-for="(doc_extra,index) in documentos_extras">
										<td>{{ index+1 }}</td>
										<td>{{ doc_extra.name }}</td>
										<td class="delete">
											<img @click="quitar_documentos_extras(index)" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar documento" title="Quitar documento">
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="3">No se agregaron documentos extras</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="row form-group">
					<label for="comentarios" class="col-form-label col-sm-2">Comentarios</label>
					<div class="col-sm-10 v-step-factura-5">
						<textarea v-model="comentarios" name="comentarios" id="comentarios" cols="30" rows="10" class="form-control"></textarea>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-3">
							<button class="btn principal-btn v-step-factura-6" @click="cargar_factura">Cargar</button>
						</div>
						<div class="col-md-3">
							<button class="btn complementary-btn" @click="cancelar_cargar_factura">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="ver_detalles" :options="{width: '70vw', close: true}" @close="ver_detalles = false">
			<div class="title">Detalles</div>
			<div class="body">
				<FacturaView :factura="factura" @observaciones="agregar_observacion" />
			</div>
		</Modal>

		<Modal v-if="error !== null" :options="{width:'40vw',type:'error',close:true}" @close="error=null">
			<div class="title">Factura no válida</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-12">{{ error.message }}</div>
				</div>
			</div>
		</Modal>

		<v-tour name="tour-buttons" :steps="buttons_steps" :options="steps_options"></v-tour>
		<v-tour name="tour-carga-factura" :steps="carga_facutra_steps" :options="steps_options" :callbacks="steps_callbacks"></v-tour>
		<v-tour name="tour-detalles-facturas" :steps="detalles_factura_steps" :options="steps_options" :callbacks="steps_callbacks_detalles"></v-tour>
		<v-tour name="tour-filtros-facturas" :steps="filtros_factura_steps" :options="steps_options" :callbacks="steps_callbacks_filtros"></v-tour>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'
	import FacturaView from '@/apps/tesoreria/components/FacturasView'

	import api_tesoreria from '@/apps/tesoreria/api/proveedores'

	export default {
		name: 'proveedores_facturas'
		,components: {
			DataGrid, Modal, FacturaView
		}
		,data: () => ({
			dataGridConfig: {
				name: 'facturas'
				,cols: {
					uuid: 'UUID'
					,total: 'Total'
					,subtotal: 'Subtotal'
					,serie: 'Serie'
					,folio: 'Folio'
					,metodo_pago: 'Método pago'
					,estatus: 'Estatus'
					,id: {
						text: 'Documentos'
						,ordeable: false
					}
				}
				,filters: {
					cols: {
						id: 'ID'
						,uuid: 'UUID'
						,total: 'Total'
						,subtotal: 'Subtotal'
						,serie: 'Serie'
						,folio: 'Folio'
						,estatus: 'Estatus'
						,created_at: 'Fecha de creación'
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					total: function(val, row, vue) {
						return '$'+vue.$helper.moneyFormat(val);
					}
					,subtotal: function(val, row, vue) {
						return '$'+vue.$helper.moneyFormat(val);
					}
					,estatus: function(val) {
						// this.$log.info('estatus', val);
						let mssg = '';
						switch(val) {
							case 0:
								mssg = '<span style="color:#FA3030;font-weight:bold">Rechazada</span>';
								break;
							case 1:
								mssg = '<span style="color:#4545FF;font-weight:bold">Sin revisar</span>';
								break;
							case 2:
								mssg = '<span style="color:#FF9E44;font-weight:bold">Aceptada sin revisión</span>';
								break;
							case 3:
								mssg = '<span style="color:#4AD627;font-weight:bold">En revisión por director de Finanzas</span>';
								break;
							case 4:
								mssg = '<span style="color:#5A96E2;font-weight:bold">En revisión por director de Finanzas y General</span>';
								break;
							case 5:
								mssg = '<span style="color:#5A96E2;font-weight:bold">En proceso de pago</span>';
								break;
							case 6:
								mssg = '<span style="color:#5A96E2;font-weight:bold">Pagada</span>';
								break;
						}

						return mssg
					}
					,id: function(val, row, vue) {
						return '<button class="btn principal-btn" name="download_files" value="'+val+'" data-json="'+(vue.$helper.toJson(row))+'"'+(vue.$auth.can('proveedores','descargar_facturas') ? '' : ' disabled')+'>Descargar</button>';
					}
				}
			}
			,factura: null
			,facturas: []
			,facturasSeleccionadas: []
			,seleccionar: false
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,subirFactura: false
			,proveedor: {}
			,comentarios: null
			,ver_detalles: false
			,observaciones: null
			,error: null
			,documentos_extras: []
			,complementos_pagos: []
			,steps_options: {
				labels: {
					buttonSkip: 'Finalizar'
					,buttonPrevious: 'Anterior'
					,buttonNext: 'Siguiente'
					,buttonStop: 'Finalizar'
				}
			}
			,steps_callbacks: {}
			,steps_callbacks_detalles: {}
			,steps_callbacks_filtros: {}
			,step_filtros: 0
			,step_carga: 0
			,buttons_steps: [
				{
					target: '.v-step-bt-1'
					,content: 'Permite recargar la lista de facturas, para obtener nuevos registros'
				}
				,{
					target: '.v-step-bt-2'
					,content: 'Permite cargar nuevas facturas y/o complementos de pagos'
				}
				,{
					target: '.v-step-bt-3'
					,content: 'Permite ver en detalle una factura'
				}
				,{
					target: '.v-step-bt-4'
					,content: 'Aquí puedes iniciar cualquiera de las guías de uso de la aplicación'
				}
				,{
					target: 'table.table'
					,content: 'Lista las facturas ya cargadas y muestra datos basicos de cada factura'
				}
				,{
					target: 'button[name="download_files"]'
					,content: 'Permite descargar todos los documentos de la factura indicada'
				}
			]
			,carga_facutra_steps: [
				{
					target: '.v-step-factura-1'
					,content: 'Damos click en el botón "Cargar Factura/Complemento"'
				}
				,{
					target: '.v-step-factura-2'
					,content: 'Damos click en recuadro para cargar documentos y buscamos el PDF de la factura/complemento de pago'
				}
				,{
					target: '.v-step-factura-3'
					,content: 'Damos click en recuadro para cargar documentos y buscamos el XML de la factura/complemento de pago"'
				}
				,{
					target: '.v-step-factura-4'
					,content: 'En caso de ser necesario anexar mas documentos ya sea para validar la factura actual o cualquier otra necesidad puedes agregar todos los documentos que requieras al dar click en el botón "Cargar documento"'
				}
				,{
					target: '.v-step-factura-5'
					,content: 'También tienes la posibilidad de agregar comentarios y/o observaciones para complementar la información de la factura/complemento de pago'
				}
				,{
					target: '.v-step-factura-6'
					,content: 'Finalmente damos click en "Cargar factura" para enviar los documentos al sistema o dar "Cancelar" para detener todo el proceso'
				}
			]
			,detalles_factura_steps: [
				{
					target: 'label:not([for="facturas_all"])'
					,content: 'Selecciona la factura que deseas ver los detalles'
				}
				,{
					target: '.v-step-bt-3'
					,content: 'Damos click en el botón "Detalles"'
				}
				,{
					target: 'label[for="proveedor"]'
					,content: 'Se visualiza información más detallada de la factura'
				}
				,{
					target: 'label[for="conceptos"]'
					,content: 'Listamos todos los conceptos de la factura'
				}
				,{
					target: 'label[for="impuestos"]'
					,content: 'Listamos todos los impuestos de la factura'
				}
				,{
					target: 'label[for="pagos"]'
					,content: 'Cuando la factura ya tiene pagos se listarán aquí'
				}
				,{
					target: 'label[for="observaciones"]'
					,content: 'Se listan las observaciones que se van agregando según el proceso que lleve la factura, también podrás ver observaciones que agreguen los operadores de BienParaBien'
				}
				,{
					target: '.agregar_observacion_btn'
					,content: 'Puedes agregar observaciones extras siempre y cuando la factura aún no sea pagada'
				}
			]
			,filtros_factura_steps: [
				{
					target: '#dataGrid-btn-add-filter'
					,content: 'Los filtros funcionan para limitar los registros a visualizar, especialmente cuando se busca un registro o registros en particular, para agregar un filtro comenzamos dando click al botón "Agregar filtro"'
				}
				,{
					target: '#filtro_columna'
					,content: 'Seleccionamos la columna a la que se le aplicará el filtro'
				}
				,{
					target: '#filtro_operador'
					,content: 'Seleccionamos el operador que se aplicará al filtro'
				}
				,{
					target: '#valor1'
					,content: 'Indicamos el valor que tendrá el filtro, esto podría ser unicamente texto o en caso de que la columna sea de un tipo en especifico deberá tener un formato en especial, por ejemplo, para fechas el formato tendrá que ser YYYY-MM-DD HH:ii:ss, puede ser solo la fecha, la fecha y hora o solo la hora, algunos operadores pueden requerir más de un dato'
				}
				,{
					target: '.dataGrid-filter-btn-add'
					,content: 'Finalmente damos click en el botón agregar o cancelar en caso que ya no desees agregar filtros'
				}
			]
		})
		,mounted() {
			this.validar_empresa();
			this.steps_callbacks = {
				onNextStep: this.tour_cargar_facturas
				,onPreviousStep: this.tour_cargar_facturas_prev
				,onStop: this.tour_cargar_facturas_stop
			}
			this.steps_callbacks_detalles = {
				onStart: this.tour_detalles_facturas
				,onNextStep: this.tour_detalles_facturas
				,onStop: this.tour_detalles_facturas_stop
			}
			this.steps_callbacks_filtros = {
				onNextStep: this.tour_filtros_facturas
				,onPreviousStep: this.tour_filtros_facturas_prev
				,onStop: this.tour_filtros_facturas_stop
			}
		}
		,methods: {
			validar_empresa: function() {
				api_tesoreria.validar_factura()
				.then(res => {
					this.$log.info('empresa', res);

					if (!res.data.success)
						this.$router.push({name: 'proveedores_empresa', params:{valida: 0}});
				})
				.catch(err => {
					this.$log.info('err', err)
					this.$helper.showAxiosError(err,'Error');
				})
			},
			cargar_facturas: function() {
				api_tesoreria.obtener_facturas(this.$auth.getUser().id, this.options)
				.then(res => {
					this.$log.info('res', res);

					this.facturas = res.data.data;
					this.dataGridConfig.paginator.pagina_actual = res.data.current_page;
					this.dataGridConfig.paginator.total_registros = res.data.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.data.per_page);
				})
				.catch(err => {
					this.$log.info('err', err);

					this.$helper.showAxiosError(err, 'Error');
				})
			}
			,dataGridActions: function(tipo, valor) {
				if (tipo == 'options') {
					this.options = valor;
					this.cargar_facturas();
				}else {
					this.facturasSeleccionadas = valor;
					this.seleccionar = true;
				}
			}
			,comentario: function(data) {
				if (data !== null && data != '')
					return data;
				else
					return 'Aún no hay comentarios';
			}
			,cerrar_comentarios: function() {
				this.factura = false;
				this.seleccionar = false;
			}
			,ver_cargar_factura: function() {
				this.subirFactura = true;
			}
			,cancelar_cargar_factura: function() {
				this.subirFactura = false;
				this.documentos_extras = [];
			}
			,cargar_factura: function(e) {
				if (document.getElementById('pdf').files.length == 0)
					return this.$helper.showMessage('Error','Tienes que agregar el PDF','error','alert');

				if (document.getElementById('xml').files.length == 0)
					return this.$helper.showMessage('Error','Tienes que agregar el XML','error','alert');


				e.target.disabled = true;
				let form = new FormData();
				form.append('pdf', document.getElementById('pdf').files[0]);
				form.append('xml', document.getElementById('xml').files[0]);
				
				if (this.documentos_extras.length > 0) {
					for(let i=0; i<this.documentos_extras.length; i++) {
						form.append('extra[]', this.documentos_extras[i]);
					}
				}

				if (this.comentarios)
					form.append('comentarios', this.comentarios);

				api_tesoreria.cargar_factura(this.$auth.getUser().id, form)
				.then(res => {
					this.$log.info('res', res);
					e.target.disabled = false;

					if (res.data.success) {
						this.subirFactura = false;
						this.cargar_facturas();

						this.$notify({
							group: 'notifications'
							,type: 'success'
							,title: 'Factura cargada'
							,text: 'La factura a sido carga con exito'
							,duration: 5000
						});
					}else {
						this.error = res.data;
						// this.$notify({
						// 	group: 'alert'
						// 	,type: 'error'
						// 	,title: 'Error al cargar factura'
						// 	,text: res.data.message
						// 	,duration: 5000
						// });
					}
				})
				.catch(err => {
					this.$log.info('err', err);
					e.target.disabled = false;

					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error al cargar factura'
						,text: err.response.data.message
						,duration: 5000
					})
				})
			}
			,descargar_archivos: function(e) {
				let factura = this.$helper.fromJson(e.target.getAttribute('data-json'));
				api_tesoreria.descargar_documentos(this.$auth.getUser().id, e.target.value, factura.uuid);
			}
			,buscar_botones: function() {
				let botones = document.querySelectorAll('button[name=download_files]').forEach(input => {
					input.removeEventListener('click',this.descargar_archivos);
					input.addEventListener('click',this.descargar_archivos);
				});
			}
			,mostrar_detalles: function() {
				if (this.facturasSeleccionadas.length > 0) {
					this.factura = this.facturasSeleccionadas[0];
					this.$log.info('factura: ', this.factura);
					this.ver_detalles = true;
				}else {
					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error'
						,text: 'Debes seleccionar una factura'
						,duration: 5000
					});
				}
			}
			,agregar_observaciones: function() {
				this.$log.info('Observacion: ', this.observaciones);
				if (this.observaciones && this.observaciones.length > 0) {
					api_tesoreria.agregar_observaciones(this.factura.id, {observacion: this.observaciones})
					.then(res => {
						this.$log.info('res', res);
						this.factura.observaciones.push(res.data);
						this.observaciones = null
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
				}
			}
			,seleccionar_documentos_extras: function() {
				document.getElementById('doc_extra').click();
			}
			,agregar_documentos_extras: function() {
				let doc_extra = document.getElementById('doc_extra');

				if (doc_extra.files.length > 0) {
					let encontrado = false;
					for(let i=0; i<this.documentos_extras.length; i++) {
						if (this.documentos_extras[i].name == doc_extra.files[0].name) {
							encontrado = true;
						}
					}

					if (!encontrado)
						this.documentos_extras.push(doc_extra.files[0]);
					else
						this.$helper.showMessage('Error','Ya se agrego un documento con el nombre "'+doc_extra.files[0].name+'"','error','alert');
				}else
					this.$helper.showMessage('Error','Debes seleccionar un XML y un PDF del complemento de pago que quieres agregar', 'error', 'alert');
			}
			,quitar_documentos_extras: function(index) {
				let doc_extras = [];
				for(let i=0; i<this.documentos_extras.length; i++) {
					if (i != index) {
						doc_extras.push(this.documentos_extras[i]);
					}
				}

				this.documentos_extras = doc_extras;
			}
			,seleccionar_complemento_pago: function() {
				document.getElementById('cargar_complemento').click();
			}
			,agregar_complemento_pago: function() {
				let encontrado = false;
				let complemento = document.getElementById('cargar_complemento');

				if (complemento.files.length > 0) {
					for(let i=0; i<this.complementos_pagos.length; i++) {
						if (complemento.files[0].name == this.complementos_pagos[i].name) {
							encontrado = true;
							i = this.complementos_pagos.length;
						}
					}

					if (!encontrado)
						this.complementos_pagos.push(complemento.files[0]);
					else
						this.$helper.showMessage('Error','El complemento de pago ya esta cargado','error','alert');
				}
			}
			,quitar_complemento_pago: function(index) {
				let complementos = [];

				for(let i=0; i<this.complementos_pagos.length; i++) {
					if (index != i) {
						complementos.push(this.complementos_pagos[i]);
					}
				}

				this.complementos_pagos = complementos;
			}
			,enviar_complementos_pago: async function() {
				let xml = document.getElementById('complemento_xml');
				let pdf = document.getElementById('complemento_pdf');

				if (xml.files.length > 0 && pdf.files.length > 0) {
					let formData = new FormData();
					formData.append('xml', xml.files[0]);
					formData.append('pdf', pdf.files[0]);

					let res = (await api_tesoreria.cargar_complementos(formData)).data;
					this.$log.info('res', res);
				}else
					this.$helper.showMessage('Error','Tienes que cargar al menos un complemento de pago con su representación grafica', 'error', 'alert');
			}
			,iniciar_tour: function(e, tipo) {
				e.preventDefault();

				switch(tipo) {
					case 'botones':
						if (this.facturas.length == 0) {
							this.$helper.showMessage('Error','Para iniciar esta guía se requiere que tengas al menos una factura', 'error','alert');
							break;
						}

						this.$tours['tour-buttons'].start();
						break;
					case 'facturas':
						this.$tours['tour-carga-factura'].start();
						break;
					case 'detalles':
						if (this.facturas.length == 0) {
							this.$helper.showMessage('Error','Para iniciar esta guía se requiere que tengas al menos una factura', 'error','alert');
							break;
						}

						this.$tours['tour-detalles-facturas'].start();
						break;
					case 'filtros':
						this.$tours['tour-filtros-facturas'].start();
						break;
				}
			}
			,tour_cargar_facturas: function(paso) {
				this.$log.info('step', paso);
				this.step_carga = paso;

				if (paso == 0) {
					this.$log.info('abrimos la carga de factura');
					this.ver_cargar_factura();
				}
				
				setTimeout(() => {
					switch(paso) {
						case 0:
						case 1:
							this.$log.info('movemos el div-step');
							document.querySelector('div.v-step').style.left = '-305px';
							break;
					}
				},10);
			}
			,tour_cargar_facturas_prev: function() {
				this.step_carga -= 1;
				this.$log.info('step_carga', this.step_carga);

				if (this.step_carga < 0)
					this.cancelar_cargar_factura();

				setTimeout(() => {
					switch(this.step_carga) {
						case 0:
						case 1:
							this.$log.info('movemos el div-step');
							document.querySelector('div.v-step').style.left = '-305px';
							break;
					}
				},10);
			}
			,tour_cargar_facturas_stop: function() {
				this.cancelar_cargar_factura();
			}
			,tour_detalles_facturas: function(paso) {
				this.$log.info('step', paso);
				
				if (typeof(paso) == 'undefined')
					document.querySelector('input[type="checkbox"][name="facturas"]').click();
				
				if (paso == 1)
					this.mostrar_detalles();
			}
			,tour_detalles_facturas_stop: function() {
				this.ver_detalles = false;
				this.seleccionar = false;
			}
			,tour_filtros_facturas: function(paso) {
				this.$log.info('step filtros', paso);
				this.step_filtros = paso;

				if (paso == 0)
					document.getElementById('dataGrid-btn-add-filter').click();

				if (paso >= 0) {
					setTimeout(() => {
						let top = 0;
						switch(paso) {
							case 0:
								top = '-127px';
								break;
							case 1:
								top = '-127px';
								break;
							case 2:
								top = '-319px'
								break;
						}

						document.querySelector('div.v-step').style.top = top;
					},20)
				}
			}
			,tour_filtros_facturas_prev: function() {
				this.step_filtros -= 1;
				this.$log.info('step filtros prev', this.step_filtros);

				if (this.step_filtros >= 0) {
					setTimeout(() => {
						let top = 0;
						switch(this.step_filtros) {
							case 0:
								top = '-127px';
								break;
							case 1:
								top = '-127px';
								break;
							case 2:
								top = '-319px'
								break;
						}

						document.querySelector('div.v-step').style.top = top;
					},20)
				}
			}
			,tour_filtros_facturas_stop: function() {
				document.querySelector('button.dataGrid-filter-btn-cancel').click();
				this.step_filtros = 0;
			}
		}
	}
</script>

<style lang="scss">
	.bpb_modal {
		.body {
			textarea {
				width: 100%;
				height: 150px;
				resize: none;
			}

			.observaciones {
				max-height: 200px;
				overflow: auto;
				
				.row {
					margin-right: 3px;
					padding: 3px;

					span.nombre {
						font-weight: bold;
					}

					span.fecha {
						font-size: 0.8em;
					}
				}

				.row:nth-child(2n+0) {
					background-color: #E6E6E6;
				}
			}

			.content-table {
				max-height: 139px;
				overflow-y: scroll;

				.other_files {
					width: 100%;

					thead {
						background-color: #000000 !important;
						color: #fff !important;

						th {
							padding: 3px 7px;
						}

						th:first-child {
							width: 25px;
							text-align: center;
						}

						th:last-child {
							width: 60px;
						}
					}

					tbody {
						tr {
							td:first-child {
								width: 20px;
								text-align: center;
							}

							td.delete {
								widows: 20px;
								text-align: center;

								img {
									width: 15px;
								}
							}
						}

						tr:nth-child(2n+2) {
							background-color: #D9D9D9;
						}
					}
				}
			}

			.table_complementos_pagos {
				width: 100%;

				thead {
					background-color: #000 !important;

					th {
						padding: 3px 7px;
					}

					th:first-child {
						width: 20px;
					}

					th:last-child {
						width: 40px;
					}
				}

				tbody {
					tr {
						td {
							padding: 3px 7px;

							img {
								width: 15px;
							}
						}

						td:last-child {
							text-align: center;
						}
					}

					tr:nth-child(2n+0) {
						background-color: #D9D9D9;
					}
				}
			}
		}
	}
</style>